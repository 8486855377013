import React from 'react'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import Parallax from '../components/parallax'
import ServiceTable from '../components/service-table'
import { Treatment, Question } from '../interfeces'

interface Props {
  data: any
  location: Location
}

const treatment: Treatment = {
  duration: '60 minutes',
  session: '1 session',
  results: 'Results 12 months',
}

const questions: Array<Question> = [
  {
    question: 'What is lip enhancement?',
    answer:
      'Lip enhancement is a cosmetic procedure which gives the patient smooth, ' +
      'plump, and youthful lips. The overall aesthetic of the lips is refined, by ' +
      'smoothening vertical lip lines, bringing out the true shape of the lips. ' +
      'The volume of the lips is also increased with carefully placed injections ' +
      'which add hydration. ' +
      'The goal is to give the patient the perfect lips he or she envisions, ' +
      'while ensuring it looks natural.',
  },
  {
    question: 'How do lip fillers work?',
    answer:
      'Lip injections fill the lips with Hyaluronic Acid to increase volume ' +
      'and remove lines. Because Hyaluronic Acid is a naturally occurring substance ' +
      'in the body, it is accepted by the body quite effortlessly.' +
      ' Over the following 12 months, it is slowly broken down until it is gone. ' +
      'This makes it a temporary lip filler, which modern dermatologists consider ' +
      'to be much safer than permanent fillers such as silicon or implants.',
  },
  {
    question: 'Does it hurt?',
    answer:
      'Many people find their procedure at Ada Aesthetics more comfortable than expected,' +
      ' and most dermal fillers contain a local anaesthetic. ' +
      'Your experienced Ada Aesthetics practitioner will ensure your treatment is best in class.',
  },
]

const LipFillers: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'Lip Fillers - Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Parallax
        titleName="Natural Looking Lip fillers"
        customClass="page_title"
      />

      <section className="ls service-single s-pt-70 s-pb-20 s-pb-sm-50 s-py-lg-100 s-pt-xl-150 s-pb-xl-100 c-gutter-60 c-mb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>
                <picture>
                  <source
                    srcSet="images/blog/lip-injections.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="images/blog/lip-injections.jpg"
                    type="image/jpeg"
                  />
                  <img
                    src="images/blog/lip-injections.jpg"
                    className="alignright"
                    alt="dermal_fillers"
                  />
                </picture>
              </p>
              <p className="excerpt">
                No wonder lip enhancement is one of our most popular treatments
                – social media is full of pouting celebrities with luscious
                lips. Full lips are the ultimate, timeless symbol of youth and
                beauty.
              </p>
              <p>
                Lip enhancement at Ada Aesthetics will give you natural looking,
                fuller lips after just one treatment. We will make sure that
                your new lips are in perfect proportion with the rest of your
                face. It’s time to feel more confident about your smile!
              </p>
            </div>
          </div>
          <ServiceTable treatment={treatment} questions={questions} />
        </div>
      </section>
    </Layout>
  )
}

export default LipFillers

export const pageQuery = graphql`
  query LipFillers {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
